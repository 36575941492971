/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string } from 'prop-types';
import { useQuery } from '@apollo/client';

import findContent from '../../../gql/queries/findContent';
import AlertMessageUI from './AlertMessageUI';

const AlertMessage = ({ queryTitle }) => {
    const ALERT_MESSAGE = findContent({
        brand: '1800flowers',
        contentType: 'alert_message',
        query: `\\"title\\" : \\"${queryTitle}\\"`,
    });
    const { data, error, loading } = useQuery(ALERT_MESSAGE);

    if (loading) {
        return <div style={{ height: '170px' }} />;
    }

    if (error) {
        return null;
    }

    if (data?.findContent?.content?.entries?.length === 0) {
        return null;
    }
    const alertData = data.findContent.content.entries[0];

    if (alertData) {
        return <AlertMessageUI alertData={alertData} />;
    }
    return null;
};

AlertMessage.propTypes = {
    queryTitle: string.isRequired,
};

export default AlertMessage;
