/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import { uid } from 'react-uid';
import { connect } from 'react-redux';
import {
    object, string, bool, shape,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import mbpLogger from 'mbp-logger';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import ContentPageBlock from './ContentPageBlock';
import OutageMessage from '../CustomerService/Partials/OutageMessage/OutageMessage';
import GraphqlUnderNavTextWidget1 from '../GraphqlCommonComponents/GraphqlUnderNavTextWidget/GraphqlUnderNavTextWidget';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import GraphqlContentPageBodyStyle from './style/GraphqlContentPageBodyStyle';
import GraphqlBreadcrumbs from '../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import { getPresentationFamily, getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import Imoc from '../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlImoc/GraphqlImoc';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import AlertMessage from '../AlertMessage/AlertMessage';

const styles = (theme) => GraphqlContentPageBodyStyle(theme);

class GraphqlContentPageBody extends PureComponent {
    getLayoutData = (blockData) => {
        const {
            brand,
        } = this.props;
        if (!blockData?.length) return null;
        const { ssrDeviceType, classes } = this.props;
        const identifyWidthObj = this.identifyWidth();
        return (
            blockData.map((block, index) => (
                <ContentPageBlock
                    block={block}
                    key={uid(block, index)}
                    ssrDeviceType={ssrDeviceType}
                    brand={brand}
                    identifyWidthObj={identifyWidthObj}
                    classOverride={classes}
                />
            ))
        );
    };

    identifyWidth = () => {
        const { ssrDeviceType } = this.props;
        const widthResolution = {
            isMobile: ssrDeviceType === 'mobile',
            isDesktop: (ssrDeviceType === 'desktop' || ssrDeviceType === 'tablet'),
        };
        return widthResolution;
    };

    checkForFixedWidth = (blocks) => {
        const style = {};
        if (!blocks) return style;
        blocks.forEach((i) => {
            if (Object.keys(i)?.[0]) return style;
            if (Object.keys(i)[0] === 'fixed_width') {
                style.maxWidth = i.fixed_width.max_width;
                style.margin = '0 auto';
            }
            return style;
        });
        return style;
    };

    setBotContent = () => {
        const {
            blocks,
        } = this.props;
        return (
            <>
                {this.getLayoutData(blocks.left_blocks)}
                {this.getLayoutData(blocks.right_blocks)}
            </>
        );
    };

    setContentLayout = () => {
        const {
            classes, blocks, brand, featureFlags, history, presentationFamily,
        } = this.props;
        if (!blocks) return null;
        const hasLeftContent = (blocks.left_blocks?.length > 0);
        const hasCenterContent = (blocks.center_blocks?.length > 0);
        const hasRightContent = (blocks.right_blocks?.length > 0);
        const imoc = blocks.page_blocks?.[0]?.imoc || {};
        const breadcrumb = blocks.seo?.breadcrumb || [];
        const identifyWidthObj = this.identifyWidth();
        const isCustomerServicePage = history.location?.pathname?.includes('customer-service') || false;
        let centerBlockGridValue = 12;    // no left nor right content
        if (hasLeftContent && hasRightContent) {
            centerBlockGridValue = 7;     // left and right content
        } else if (hasLeftContent) {
            centerBlockGridValue = 9;     // left content only
        } else if (hasRightContent) {
            centerBlockGridValue = 10;    // right content only
        }

        const checkBlocksData = (hasLeftContent || hasCenterContent || hasRightContent) || false;
        let isMarketplacePage = false;
        if (typeof window !== 'undefined') {
            isMarketplacePage = window?.location?.href.includes('/marketplace');
        }
        return (
            <div className="nonBot" data-pagetype="content">
                {breadcrumb.length > 0 && (
                    <Grid container className={isMarketplacePage ? classes.marketPlaceBreadCrumb : ''}>
                        <Grid role="region" aria-label="BreadCrumb" container className={`${classes.content} ${classes.setPaddingBreadcrumb}`}>
                            <GraphqlBreadcrumbs breadCrumbArray={breadcrumb} />
                        </Grid>
                    </Grid>
                )}
                {imoc.imoc_group && (
                    <Grid container className={isMarketplacePage ? classes.marketPlaceImocContainer : classes.imocContainer}>
                        <Imoc brand={brand?.domain} imocBlock={imoc} />
                    </Grid>
                )}
                <Grid container className={`${classes.content} nonBot ${!checkBlocksData ? classes.containerStyle : ''}`}>
                    {hasLeftContent && identifyWidthObj.isDesktop
                        && (
                            <Grid item md={3}>
                                <Grid className={classes.leftBlock}>
                                    <Grid container>
                                        {this.getLayoutData(blocks.left_blocks, identifyWidthObj)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    {hasCenterContent
                        && (
                            <Grid item md={centerBlockGridValue} xs={12} sm={12}>
                                <Grid className={classes.centerBlock} style={this.checkForFixedWidth(blocks.center_blocks)}>
                                    <Grid container>
                                        {featureFlags['is-atlas-maintenance-alert-enabled'] && (
                                            <OutageMessage />
                                        )}
                                        {presentationFamily === 'food' && isCustomerServicePage && featureFlags['is-cs-delivery-alert-message-enabled'] && (
                                            <AlertMessage queryTitle="Customer Service" />
                                        )}

                                        {this.getLayoutData(blocks.center_blocks, identifyWidthObj)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    {hasRightContent && identifyWidthObj.isDesktop
                        && (
                            <Grid item md={2}>
                                <Grid className={classes.rightBlock}>
                                    <Grid container>
                                        {featureFlags['is-atlas-maintenance-alert-enabled'] && (
                                            <OutageMessage />
                                        )}
                                        {this.getLayoutData(blocks.right_blocks, identifyWidthObj)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    {hasLeftContent && identifyWidthObj.isMobile
                        && (
                            <Grid item md={12} xs={12} sm={12}>
                                <Grid className={classes.leftBlock}>
                                    <Grid container>
                                        {this.getLayoutData(blocks.left_blocks)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    {hasRightContent && identifyWidthObj.isMobile
                        && (
                            <Grid item md={2}>
                                <Grid className={classes.rightBlock}>
                                    <Grid container>
                                        {featureFlags['is-atlas-maintenance-alert-enabled'] && (
                                            <OutageMessage />
                                        )}
                                        {this.getLayoutData(blocks.right_blocks, identifyWidthObj)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </div>
        );
    };

    render() {
        const {
            ssrDeviceType,
            untData,
            seo,
            presentationFamily,
            isBot,
        } = this.props;
        mbpLogger.logDebug({
            appName: process.env.npm_package_name,
            message: `ssrdevice is: ${ssrDeviceType}`,
        });
        return (
            <>
                <GraphqlSeoHeadTags seoData={seo} />
                {untData !== null
                    ? (<GraphqlUnderNavTextWidget1 presentationFamily={presentationFamily} data={untData?.[0]?.spots?.[0]?.content_spot} />) : null}
                {isBot ? this.setBotContent() : this.setContentLayout()}
            </>
        );
    }
}

GraphqlContentPageBody.propTypes = {
    brand: object.isRequired,
    ssrDeviceType: string.isRequired,
    untData: object,
    blocks: object,
    seo: object,
    classes: object,
    presentationFamily: string,
    isBot: bool,
    featureFlags: object.isRequired,
    history: shape({
        location: shape({
            pathname: string,
        }).isRequired,
    }).isRequired,
};

GraphqlContentPageBody.defaultProps = {
    untData: '',
    blocks: {},
    seo: {},
    classes: {},
    presentationFamily: 'flowers',
    isBot: false,
};

const mapStateToProps = (state) => ({
    theme: state.theme,
    presentationFamily: getPresentationFamily(state),
    isBot: getIsBot(state),
    featureFlags: getFeatureFlags(state),
});

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps),
)(GraphqlContentPageBody);
