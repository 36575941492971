/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { uid } from 'react-uid';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import { object, bool, string } from 'prop-types';
import { compose } from 'recompose';

import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import * as app from '../../../../state/ducks/App';
import { GRAPHQL_ENV } from '../../../gql';
import HomePageSkeleton from '../GraphqlSkeletonComponents/HomePageSkeleton';
import GraphqlContentPageBody from './GraphqlContentPageBody';
import GraphqlContentPageBodyBot from './GraphqlContentPageBodyBot';
import MarketplacePageBlock from '../MarketplacePage/MarketplacePageBlock';
import MarketplaceProductDetailsQuery from '../GraphqlProductPage/Partials/MarketplaceProductDetailsQuery';
import HostedDinners from '../Template_HostedDinners/HostedDinners';

import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import { coreBrowsingPageViewSuccessTest } from '../../../helpers/tracking/common/commonTrackingHelpers';
import getLocale from '../../../helpers/getLocale';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import RenderWhenVisible from '../../../helpers/componentVisibility/RenderWhenVisible';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const appSelectors = app.selectors;
const brandSelectors = app.ducks.brand.selectors;

const GraphqlContentPage = ({
    brand, page, isBot, ssrDeviceType, product, isDescriptionTabEnabled, productBaseContent, presentationFamily,
}) => {
    const location = useLocation();
    const FOLD_INDEX = 4;

    let CONTENTPAGE_QUERY = gql`
        query ContentPageQuery {
            findContentPage(brand: "${brand['domain-name']}", url: "${location.pathname}", contentType: "content_page", environment: "${GRAPHQL_ENV}", locale: "en-us") {
                content
            }
        }
    `;

    if (isDescriptionTabEnabled) {
        CONTENTPAGE_QUERY = gql`
            query ContentPageQuery {
                findContentPage(brand: "${brand['domain-name']}", url: "/marketplace/sellers", contentType: "content_page", environment: "${GRAPHQL_ENV}", locale: "en-us") {
                    content
                }
            }
        `;
    }

    // Centralized TOU and Privacy Notice content
    if (location.pathname === '/About-Us-Privacy-Policy') {
        CONTENTPAGE_QUERY = gql`
        query ContentPageQuery {
            findContent(brand: "global", contentType: "privacy_notice", environment: "${GRAPHQL_ENV}", locale: "${getLocale()}") {
                content
            }
        }
    `;
    }
    if (location.pathname === '/About-Us-Terms-of-Use') {
        CONTENTPAGE_QUERY = gql`
        query ContentPageQuery {
            findContent(brand: "global", contentType: "terms_of_use", environment: "${GRAPHQL_ENV}", locale: "${getLocale()}") {
                content
            }
        }
    `;
    }

    // hosted dinners lander
    // need to eventually serve recipes from CMS as well
    if (location.pathname.match('/hosted-dinners')?.length && location.pathname !== '/hosted-dinners-recipes') {
        return <HostedDinners path={location.pathname} />;
    }

    const renderPageBlocksComponent = (blocks, isBirthdayLPLayout) => (
        <>
            {blocks.map((block, index) => {
                if (location.pathname === '/marketplace' && presentationFamily === 'flower') {
                    if (index <= FOLD_INDEX) {
                        return (
                            <MarketplacePageBlock
                                key={uid(block, index)}
                                block={block}
                                isBirthdayLPLayout={isBirthdayLPLayout}
                            />
                        );
                    }
                    return (
                        <RenderWhenVisible>
                            <MarketplacePageBlock
                                key={uid(block, index)}
                                block={block}
                                isBirthdayLPLayout={isBirthdayLPLayout}
                            />
                        </RenderWhenVisible>
                    );
                }
                if (block.interactive_catalog?.target_div) {
                    const script = `!function(a,b,c,d,e,f,g){var h=d+"app/redpepper/redpepper.min.js",i=b.createElement(c),j=b.getElementsByTagName(c)[0],k=function(){redpepper.init(d,"red-pepper-catalog",e,f,g)};i.async=1,i.onload=k, i.src=h,j.parentNode.insertBefore(i,j)}(window,document,"script","https://app.redpepperdigital.net/",${block.interactive_catalog?.catalog_id})`;
                    return (
                        <>
                            {<ReactMarkdown source={block.interactive_catalog?.target_div} escapeHtml={false} />}
                            {<Helmet><script>{script}</script></Helmet>}
                        </>
                    );
                }
                return (
                    <MarketplacePageBlock
                        key={uid(block, index)}
                        block={block}
                        isBirthdayLPLayout={isBirthdayLPLayout}
                    />
                );
            })}
        </>
    );

    return (
        <Query query={CONTENTPAGE_QUERY}>
            {({
                loading, error, data,
            }) => {
                if (loading) {
                    return <HomePageSkeleton />; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: CONTENTPAGE_QUERY,
                        component: 'GraphqlContentPage.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });

                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: CONTENTPAGE_QUERY,
                        component: 'GraphqlContentPage.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });

                    return null;
                }
                if (!data?.findContentPage?.content && !data?.findContent?.content) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: CONTENTPAGE_QUERY,
                        component: 'GraphqlContentPage.js',
                        message: 'No content returned for query',
                    });
                    return null;
                }

                const content = data.findContentPage || data.findContent || '';
                const blocks = content?.content?.entries?.[0] || null;
                const isBirthdayLPLayout = blocks?.isbirthdaylp || false;
                const pageBlocks = blocks?.page_blocks || [];

                if (isDescriptionTabEnabled) {
                    const faqBlock = pageBlocks.find((block) => {
                        if (Object.keys(block)[0] === 'marketplace_lp_faqs') {
                            return block.marketplace_lp_faqs;
                        }
                        return null;
                    });

                    return (
                        <MarketplaceProductDetailsQuery faqBlock={faqBlock} product={product} productBaseContent={productBaseContent} />
                    );
                }

                const untData = content.underNavText?.entries || null;
                const SEO = content.content.entries?.[0]?.seo || null;
                const domainURL = `https://pwa.${brand.domain}.com` || '';

                return (
                    <>
                        <Helmet><meta data-react-helmet="true" property="og:type" content="website" /></Helmet>
                        <GraphqlSeoHeadTags seoData={SEO} />
                        {isBot && (
                            <GraphqlContentPageBodyBot
                                untData={untData}
                                seo={SEO}
                                blocks={blocks}
                                brand={brand}
                            />
                        )}
                        <GraphqlContentPageBody
                            brand={brand}
                            page={page}
                            blocks={blocks}
                            untData={untData}
                            seo={SEO}
                            domainURL={domainURL}
                            ssrDeviceType={ssrDeviceType}
                        />
                        {pageBlocks.length > 0 && (
                            <>
                                {renderPageBlocksComponent(pageBlocks, isBirthdayLPLayout)}
                            </>
                        )}
                    </>
                );
            }}
        </Query>
    );
};

GraphqlContentPage.propTypes = {
    brand: object.isRequired,
    page: object,
    isBot: bool,
    ssrDeviceType: string.isRequired,
    product: object,
    isDescriptionTabEnabled: bool,
    productBaseContent: object,
    presentationFamily: string.isRequired,
};
GraphqlContentPage.defaultProps = {
    isBot: false,
    page: {},
    product: {},
    isDescriptionTabEnabled: false,
    productBaseContent: {},
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
    ssrDeviceType: appSelectors.getSSRDeviceType(state),
    isBot: getIsBot(state),
    presentationFamily: getPresentationFamily(state),
});

const enhance = compose(
    connect(mapStateToProps),
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
    }),
);

export default enhance(GraphqlContentPage);
